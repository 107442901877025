.placeholder,
.placeholder-lg,
.placeholder-sm {
    color: #dcdcde !important;
    background: #dcdcde !important;
    cursor: wait !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    mask-size: 100% 200px;
    mask-position: left bottom;
}

body:hover {
    mask-position: left bottom;
    -webkit-mask-position: left top;
}

body.artemis-style {
    height: 100%;
    width: 100%;
    overflow: hidden !important;
}

body.non-artemis-style {
    height: 100%;
    width: 100%;
}

th {
    position: sticky;
    top: 0;
}
