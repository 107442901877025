.tableData {
    padding: 16px !important;
}

.tableProviderHeaderData,
.providerTableData,
.tableAttributionHeaderData,
.attributionTableData {
    padding: 8px 0px 8px 16px !important;
}

.tableHeaderData,
.tableCategoryHeaderData,
.categoryTableData {
    padding: 8px 16px !important;
}

.categoryBadge {
    padding: 3px 6px !important;
    border-radius: 5px !important;
    vertical-align: center !important;
}
