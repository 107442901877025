//
// _menu.scss
//

#sidebar-menu {
    padding: 10px 0 30px 0;

    .mm-active {
        >.has-arrow {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    .has-arrow {
        &:after {
            content: "\F0140";
            font-family: 'Material Design Icons';
            display: block;
            float: right;
            transition: transform .2s;
            font-size: 1rem;
        }
    }

    ul {
        li {
            a {
                display: block;
                padding: .625rem 1.5rem;
                color: $sidebar-dark-menu-item-color;
                position: relative;
                font-size: 13px;
                transition: all .4s;


                &:hover {
                    color: $sidebar-dark-menu-item-hover-color;

                    i {
                        color: $sidebar-dark-menu-item-hover-color;
                    }
                }
            }

            .badge {
                margin-top: 4px;
            }

            ul.sub-menu {
                padding: 0;

                li {

                    a {
                        padding: .4rem 1.5rem .4rem 3.5rem;
                        font-size: 13px;
                        color: $sidebar-dark-menu-sub-item-color;
                    }

                    ul.sub-menu {
                        padding: 0;

                        li {
                            a {
                                padding: .4rem 1.5rem .4rem 4.5rem;
                                font-size: 13.5px;
                            }
                        }
                    }
                }
            }
        }

    }
}

.menu-title {
    padding: 12px 20px !important;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: $sidebar-dark-menu-item-icon-color;
    font-weight: $font-weight-semibold;
}

.mm-active {
    color: $sidebar-dark-menu-item-active-color !important;
    .active {
        color: $sidebar-dark-menu-item-active-color !important;

        i {
            color: $sidebar-dark-menu-item-active-color !important;
        }
    }
    > i {
        color: $sidebar-dark-menu-item-active-color !important;
    }
}

.main-content {
    margin-left: $sidebar-collapsed-width;
    overflow: hidden;
    background: #E3E3E5;

    .content {
        padding: 0 15px 10px 15px;
        margin-top: $header-height;
    }
}

.footer {
    left: $sidebar-collapsed-width;
}

.navbar-brand-box {
    width: $sidebar-collapsed-width !important;
    background: $sidebar-dark-bg;
}

.logo {
    span.logo-lg {
        display: none;
    }

    span.logo-sm {
        display: block;
    }
}

.vertical-menu {
    position: fixed;
    width: $sidebar-collapsed-width !important;
    z-index: 5;
    background: $sidebar-dark-bg;
    bottom: 0;
    margin-top: 0;
    top: $header-height;
    box-shadow: $box-shadow;

    .simplebar-mask,
    .simplebar-content-wrapper {
        overflow: visible !important;
    }

    .simplebar-scrollbar {
        display: none !important;
    }

    .simplebar-offset {
        bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
        padding: 10px 0 30px 0;

        .menu-title,
        .badge,
        .collapse.in {
            display: none !important;
        }

        .nav.collapse {
            height: inherit !important;
        }

        .has-arrow {
            &:after {
                display: none;
            }
        }

        > ul {
            > li {
                position: relative;
                white-space: nowrap;

                > a {
                    display: block;
                    color: $sidebar-dark-menu-item-color;
                    padding: 15px 20px;
                    min-height: 55px;
                    transition: none;
                    font-size: 13px;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $sidebar-dark-menu-item-hover-color;
                    }

                    i {
                        font-size: 1.45rem;
                        margin-left: 4px;
                        display: inline-block;
                        min-width: 1.75rem;
                        padding-bottom: .125em;
                        line-height: 1.40625rem;
                        vertical-align: middle;
                        color: $sidebar-dark-menu-item-icon-color;
                        transition: all .4s;
                    }

                    span {
                        display: none;
                        padding-left: 25px;
                    }
                }

                &:hover {
                    color: $sidebar-dark-menu-item-hover-color;
                    > a {
                        position: relative;
                        width: calc(150px + #{$sidebar-collapsed-width});
                        color: $sidebar-dark-menu-item-hover-color;
                        background-color: darken($sidebar-dark-bg, 4%);
                        transition: none;

                        i{
                            color: $sidebar-dark-menu-item-hover-color;
                        }

                        span {
                            display: inline;
                        }
                    }

                    >ul {
                        display: block;
                        left: $sidebar-collapsed-width;
                        position: absolute;
                        width: 190px;
                        height: auto !important;
                        box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);

                        ul {
                            box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
                        }

                        a {
                            box-shadow: none;
                            padding: 8px 20px;
                            position: relative;
                            width: 190px;
                            z-index: 6;
                            color: $sidebar-dark-menu-sub-item-color;

                            &:hover {
                                color: $sidebar-dark-menu-item-hover-color;
                            }
                        }
                    }
                }
            }

            ul {
                padding: 5px 0;
                z-index: 9999;
                display: none;
                background-color: $sidebar-dark-bg;

                li {
                    &:hover {
                        >ul {
                            display: block;
                            left: 190px;
                            height: auto !important;
                            margin-top: -36px;
                            position: absolute;
                            width: 190px;
                        }
                    }

                    >a {
                        span.pull-right {
                            position: absolute;
                            right: 20px;
                            top: 12px;
                            transform: rotate(270deg);
                        }
                    }
                }

                li.active {
                    a {
                        color: $gray-100;
                    }
                }
            }
        }
    }

}
