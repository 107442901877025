#admin-table::-webkit-scrollbar-track {
    margin-top: 47px !important;
    margin-left: 10px !important;
}

#transactions-scrollbar > div.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar-track {
   background: #f0f2f3 !important;
}

::-webkit-scrollbar {
    width: 5px !important;
    visibility: hidden !important;
}

::-webkit-scrollbar:horizontal {
    height: 5px !important;
}

:hover::-webkit-scrollbar {
    width: 5px !important;
    display: flex !important;
    visibility: visible !important;
}

::-webkit-scrollbar-track {
    border-radius: 100px !important;
    display: flex !important;
    width: 5px !important;
}

::-webkit-scrollbar-thumb {
    background: #aba8ac !important;
    border-radius: 100px !important;
    visibility: hidden !important;
    display: flex !important;
    width: 5px !important;
}

:hover::-webkit-scrollbar-thumb {
    background: #aba8ac !important;
    border-radius: 100px !important;
    display: flex !important;
    width: 5px !important;
    visibility: visible !important;
}

body::-webkit-scrollbar-thumb {
    visibility: visible !important;
}

body::-webkit-scrollbar-track {
    margin-top: 73px !important;
    margin-left: 10px !important;
}

body.non-artemis-style::-webkit-scrollbar {
    width: 0px !important;
}