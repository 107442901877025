.arcaneTablePlaceholderTitle {
    width: 12%;
}

.arcaneTableW2 {
    width: 2%;
}

.arcaneTableW3 {
    width: 3%;
}

.arcaneTableW6 {
    width: 6%;
}

.arcaneTableW8 {
    width: 8%;
}

.arcaneTableW15 {
    width: 15%;
}

.arcaneTableW20 {
    width: 20%;
}

.arcaneTableW30 {
    width: 30%;
}

.arcaneTableColumnLast {
    width: 8%;
    padding-top: 455px;
}