.arcaneTitlePlaceholder {
    margin-bottom: 6px;
    margin-top: 9px;
    height: 30px;
    width: 90px !important;
}

.zoomToggleTitlePlaceholder {
    width: 70px !important;
    height: 17px;
}

.zoomTogglePlaceholder {
    width: 44px;
    height: 17px;
    border-radius: 8.5px;
}

.arcaneMapPlaceholder {
    height: 480px;
}
