//
// _buttons.scss
//

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

// Button Push Animation
.buttonPush {
    transition: transform 0.1s;
}

.buttonPush:active {
    transform: scale(0.9);
    transition: transform 0.1s;
}