.my-tooltip {
    pointer-events: none !important;
    position: absolute;
    z-index: 99999999999999999 !important;
    width: 300px !important;
}

.noTooltipTransition {
    transition: none !important;
}

.vis-tooltip {
    position: absolute;
    padding: 5px;
    max-width: 800px;
    white-space: nowrap;
    color: #000000;
    background-color: #fdfdfd;
    border: none;
    box-shadow: 3px 3px 10px rgba(22, 22, 22, 0.75);
    pointer-events: none;
    z-index: 11;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}