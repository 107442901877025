#newsCard {
    transition: all 0.2s ease-in-out;
}

#newsCard:hover {
    transform: scale(1.01);
}

.transition-left {
    transition: left 0.5s ease-out;
}

.transition-width {
    transition: width 0.3s ease-in;
}

.transition-transform {
    transition: transform 200ms linear;
}