.theadPlaceholder {
    height: 3rem;
}

.placeholderHeaderW3 {
    width: 3%;
}

.placeholderHeaderW4 {
    width: 4%;
}

.placeholderHeaderW5 {
    width: 5%;
}

.placeholderHeaderW8 {
    width: 8%;
}

.placeholderHeaderW13 {
    width: 13%;
}

.placeholderHeaderW14 {
    width: 14%;
}

.placeholderCardTitle {
    width: 8%;
}

.placeholderCardSubtitle {
    width: 15%;
}

.placeholderSearchIcon {
    width: 300px;
}

.badge-placeholder {
    padding: 5px 8px;
    border-radius: 12px;
}

.role-badge {
    padding: 4px 8px;
    border-radius: 4px;
}

.circular-button {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.openCreateModalButtonPlaceholder {
    width: 10rem;
    height: 2.5rem;
}

.downloadIconPlaceholder {
    width: 1.5rem;
    height: 1.5rem;
}

.adminCheckboxPlaceholder {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
}
